// Vars ---------------------------------------------------------------- //
$basicLightbox__background: rgba(0, 0, 0, .8) !default;
$basicLightbox__zIndex: 5555 !default;
$basicLightbox__duration: .4s !default;
$basicLightbox__timing: ease !default;

.modale {
  width: 70%;
  margin: auto;
  position: relative;
/*  left: 50%;
  position: relative;
  transform: translateX(-50%);*/

  &-close {
    display: none;
    color: #fff;
    position: absolute;
    top: -38px;
    right: 0;
    font-size: 28px;
  }
}

.lg-components {
  background-color: #23798C;
  transition: transform .35s ease-out 1s;
  
  svg {
    width: 15px;
  }
  a {
    text-decoration: underline;
  }
}

.button-group {
  margin-bottom: 16px;
  margin-top: 4px;
}

.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  padding-top: 591.44px / 1127.34px * 100%;
  background: white;
  position: relative;
}
.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// basicLightbox ------------------------------------------------------- //
.basicLightbox {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $basicLightbox__background;
  opacity: .01; // Start with .01 to avoid the repaint that happens from 0 to .01
  transition: opacity $basicLightbox__duration $basicLightbox__timing;
  z-index: $basicLightbox__zIndex;
  will-change: opacity;

  &--visible {
    opacity: 1;
  }

  &__placeholder {
    max-width: 100%;
    transform: scale(.9);
    transition: transform $basicLightbox__duration $basicLightbox__timing;
    z-index: 1;
    will-change: transform;

    > img:first-child:last-child,
    > video:first-child:last-child,
    > iframe:first-child:last-child {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      max-width: 95%;
      max-height: 95%;
    }

    > video:first-child:last-child,
    > iframe:first-child:last-child {
      pointer-events: auto;
    }

    > img:first-child:last-child,
    > video:first-child:last-child {
      width: auto;
      height: auto;
    }
  }

  &--img &__placeholder,
  &--video &__placeholder,
  &--iframe &__placeholder {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &--visible &__placeholder {
    transform: scale(1);
  }

}