.col {
  padding-right: 4px;
  padding-left: 4px;
  @media (min-width: 960px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.page-portfolio .container-fluid {
  padding-right: 7px;
}

.photo-gallery, .aniimated-thumbnials {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0;

  .hidden {
    display: none;
  }

  .gutter-sizer {width: 1%; }

  &-item, .grid-sizer {
    overflow: hidden;
    margin-bottom: 1%;
    width: 49.5%;

    @media (min-width: 960px) {
      width: 32.666%;
    }
    @media (min-width: 1760px) {
      width: 24.25%;
    }
  }

  &-item {
    float: left;

    &.pano {
      a::after {
        content: "";
        position: absolute;
        left: 5px;
        top: 5px;
        width: 30px;
        height: 30px;
        background: url(/assets/img/icon_360.png) no-repeat;
        background-size: 100% 100%;
        @media (min-width: 960px) {
          width: 50px;
          height: 50px;
          left: 10px;
          top: 10px;
        }
      }
    }

    &.real {
      a::after {
        content: "";
        position: absolute;
        left: 5px;
        top: 5px;
        width: 30px;
        height: 30px;
        background: url(/assets/img/icon_real.png) no-repeat;
        background-size: 100% 100%;
        opacity: 0.8;
        @media (min-width: 960px) {
          width: 50px;
          height: 50px;
          left: 10px;
          top: 10px;
        }
      }
    }
    &.webgl {
      a::after {
        content: "";
        position: absolute;
        left: 5px;
        top: 5px;
        width: 30px;
        height: 30px;
        background: url(/assets/img/icon_webgl.png) no-repeat;
        background-size: 100% 100%;
        opacity: 0.9;
        @media (min-width: 960px) {
          width: 50px;
          height: 50px;
          left: 10px;
          top: 10px;
        }
      }
    }

    a {
      display: block;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255,179,0, 0);
        transition: all 0.25s ease-in-out;
      }

      &:hover {
        &::before {
          background-color: rgba(255,179,0, 0.45);
          transition: all 0.25s ease-in-out;
          z-index: 1;
        }
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 6px;
  }
}