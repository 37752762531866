.credit{
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 20px 0;
  color: #fff;
}

#gallery-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.title {
  width: 100%;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 24px;
}

#panorama-container {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0,0,0,0.9);
  transform: scale(0,0);
  opacity: 0;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  z-index:5555;
}

#panorama-container.open {
  opacity: 1;
  transform: scale(1,1);
}

.photo {
  flex-grow: 1;
  width: 360px;
  height: 240px;
  background-size: cover;
  margin: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.photo:hover {
  border-color: #000;
}

#progress-bar {
  position: fixed;
  top: 0;
  width: 0;
  height: 5px;
  background-color: #fff;
  transition: opacity 0.5s ease;
}

.close {
  width: 44px;
  height: 44px;
  position: absolute;
  right: 0;
  margin: 20px;
  cursor: pointer;
  opacity: 1;
  z-index: 5555;
}

.close > i {
  color: #fff;
  font-size: 44px;
}

#main-container {
  width: 100%;
  height: 80%;
  align-self: center;
  background-color: #000;
}