body {
  background-color: #fff;
}
html, body, main, section.full {
  width: 100%;
  height: 100%;
}
.clear {
  clear: both!important;
}
.panolens-canvas:hover {
  cursor: grab;
}
.background.full {
  background-color: #4281dc!important;
}
.page-home .panolens-canvas {
  background-color: #4281dc;
  background: url("https://ik.imagekit.io/azvezpw8xi/pano/loading-low.png?updatedAt=1682527432915") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
ul, p, h1 {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

section.background {
  position: fixed;
  top: 0;
  left: 0;
}

.page-portfolio + .container-fluid {
    margin-top: 85px;

  @media (min-width: 960px) {
    margin-top: 65px;
  }
}

body.portfolio-active .navigation a[href="portfolio"] {
  font-weight: 700;
}
body.about-active .navigation a[href="a-propos"] {
  font-weight: 700;
}
body.active .navigation a[href="/"] {
  font-weight: 700;
}

.fixed-top {
  &.contact-page {
    position: relative;
    background-color: #000;
    a {
      color: #fff;
    }
  }
}

.home {
  &-content {
    position: relative;
    user-select: none;
  }

  &-link {
    text-align: center;
    margin-top: 12px;
  }

  a {
    padding: 12px 33px;
    background-color: white;
    color: #000;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 14px;
    display: inline-block;
    border: none;
    border-radius: 40px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.35);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;

    &:hover {
      background-color: rgba(255,179,0, 1);
      box-shadow: 0 15px 20px rgba(255,179,0, 0.5);
      color: #fff;
      text-decoration: none;
    }

    @media (min-width: 960px) {
      font-size: 20px;
    }
  }
}

.page-home {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

#panorama-container {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0,0,0,0.9);
  transform: scale(0,0);
  opacity: 0;
  /*  -webkit-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;*/

  #main-container {

  }
}

#panorama-container.open {
  opacity: 1;
  transform: scale(1,1);

  #main-container {

  }
}

#main-container {
  width: 100%;
  height: 100%;
  align-self: center;
  background-color: #000;
}
.portfolio #progress {
  top: 45px!important;
}
#progress {
  position: absolute;
  width: 100%;
  z-index: 5;
  top: 57px!important;
  height: 8px!important;
}

#progress #bar{
  background-color: red;
}

.close {
  width: 44px;
  height: 44px;
  position: absolute;
  right: 0;
  margin: 20px;
  cursor: pointer;
}

.close > i {
  color: #fff;
  font-size: 44px;
}

#bar {
  background-color: #fff;
  height: 100%;
  transition: width 0.1s ease;
}

#bar.hide {
  opacity: 0;
  transition: opacity 1s ease;
}

.filters-button-group {
  overflow-x: auto;
  flex-wrap: nowrap;
  display: flex;
  margin-bottom: 8px!important;
  padding-bottom: 8px;

  .button {
    margin-bottom: 4px;
    margin-right: 12px;
    background-color: rgb(227, 227, 227);
    color: rgb(31, 31, 31);
    box-shadow: none;
    outline: none;
    border: 0;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 12px;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;

    &:hover {
     // background-color: rgba(194, 231, 255);
      background-color: rgba(255, 179, 0, 0.4);
    }

    &.is-checked {
      //background-color: rgb(194, 231, 255);
      background-color: rgba(255, 179, 0, 0.9);
      color: rgb(4, 30, 73);
      border: 2px solid #23798C;
      outline: 0;
      box-shadow: inset 0 0 0 2px rgb(255, 255, 255);
    }

    @media (min-width: 960px) {
      margin-bottom: 8px;
      margin-right: 8px;
      padding: 8px 16px;
      font-size: 16px;
    }
  }
}

.navigation {
  padding: 12px 0;
  font-size: 13px;
  background-color: rgba(255, 255, 255, 0.95);

  @media (min-width: 960px) {
    font-size: 18px;
    padding: 16px;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    text-decoration: none;

    @media (min-width: 960px) {
      justify-content: flex-start;
    }

    li {
      @media (min-width: 960px) {
        margin-right: 50px;
      }

      a {
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        text-decoration: none;
        color: black;
        text-transform: uppercase;
        letter-spacing: 0.5px;

        &:hover {
          text-decoration: underline;
        }

        &.active {
          //font-weight: bold;
          //color: rgba(255,179,0, 1);
        }
      }
    }
  }
}

.navigation-v2 {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  background: linear-gradient(0deg, transparent 0, #fff 12px);
  padding: 0 1.2rem 1.2rem 1.2rem;

  @media (min-width: 960px) {
    background-color: rgba(255, 255, 255, 0.95);
    flex-direction: row;
    padding: 0 1.2rem;
  }

  ul {
    display: flex;
    align-items: center;
    li {
      margin-right: 1.2rem;
      @media (min-width: 960px) {
        margin-right: 1.5rem;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        color: #1f1f1f;
        font-size: 0.9rem;
        font-family: 'Quicksand', sans-serif;

        @media (min-width: 960px) {
          font-size: 1rem;
        }
      }
    }
  }

  .resume {
    a {
      z-index: 1;
      border-radius: 40px;
      padding: 2px 2px;
      height: 30px;
      text-decoration: none;
      display: flex;
      position: relative;
      overflow: hidden;
      color: #23798C;

      @media (min-width: 960px) {
        height: 35px;
      }

      &::before {
        top: -700%;
        right: -700%;
        bottom: -700%;
        left: -700%;
        content: "";
        position: absolute;
        background-image: conic-gradient(from 286deg at 50% 50%, #23798C, #E3A504, #23798C);
        animation: gradientloop 3s linear infinite;
      }
      .resume-inner {
        background-color: #fff;
        border-radius: 36px;
        padding: 0 16px;
        display: flex;
        position: relative;
        line-height: 1.8;
        @media (min-width: 960px) {
          line-height: 1.8;
        }
      }
    }
  }

  @keyframes gradientloop {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(1turn)
    }
}

  .nav-title {
    display: flex;
    align-items: center;
    color: #23798C;
    height: 42px;

    @media (min-width: 960px) {
      height: 65px;
    }

    .nav-logo {
      font-family: 'Quicksand', sans-serif;
      font-size: 1%;
      letter-spacing: -0.08rem;
      font-size: 1.6rem;

      @media (min-width: 960px) {
        font-size: 2.3rem;
        letter-spacing: -0.12rem;
      }
    }
    .nav-subtitle {
      font-family: 'Quicksand', sans-serif;
      font-size: 0.9rem;
      letter-spacing: 0rem;
      margin: 0;
      position: relative;
      top: 1px;

      @media (min-width: 960px) {
        letter-spacing: -0.04rem;
        font-size: 1.3rem;
        top: 2px;
      }

      &::before {
        content: "";
        padding: 0 .3rem;

        @media (min-width: 960px) {
          padding: 0 .4rem;
        }
      }
    }
  }
}