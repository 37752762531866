/*---------------------------------------------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------ 0. Page Contact ----------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------------------------------------------------------*/
/* --- Global --- */
.banner-bg {
  background-attachment: fixed;

  -webkit-background-clip: border-box;
  -moz-background-clip: border-box;
  background-clip: border-box;
  background-origin: padding-box;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  position: relative;
  z-index: 50;
  height:100%;
  width: 100%;
}
/* --- section --- */
.contact {
  /* background-image: url("../img/contact/subway.jpg");
  background-color: #00446D;
  overflow: hidden;*/
  height: auto!important;
  overflow: hidden;
  position: relative;
  color: #fff;
  background-color: #000;
}

.bgContact {
  background-image: url("/assets/img/contact/subway.jpg");
  background-size: cover;
  position: fixed;

  height: 100%;
  width: 100%;

  right: 0;
  left: 0;
  bottom: 0;
  top: 0;

  z-index: 5;
}

.pageContact {
  background-color: #000;
  color: #fff;
  height: auto;
  min-height: 100%;
}
.pageContact #content-menu {
  height: 73px;
}

.contact--top {
  background: url("/assets/img/contact/top-bg-r.png") left repeat-x;
  height: 41px;
  position: absolute;
  left: 0;
  right: 0;
  padding-top: 0;
  z-index: 555;
}

.contact--bottom {
  background: url("/assets/img/contact/bottom-bg-r.png") left repeat-x;
  height: 45px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 555;
}
/* Conteneur de la page */
.contact--content {
  width: 100%;
  max-width: 960px;
  margin: auto;
  position: relative;
  z-index: 555;
  overflow: hidden;
  padding-bottom: 95px;
  height: auto;
  /*padding-top: 73px;*/
}
.contact__left {
  float: left;
  width: 45%;
  padding-top: 20px;
}

.contact__left h1 {
  font-family: 'Yanone Kaffeesatz', sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 78px;
  border-bottom: 1px solid #fff;
  padding-bottom: 0px;
  text-shadow: 1px 1px 2px #000;
}

.contact__left h2 {
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 28px;
  clear: both;
  text-shadow: 1px 1px 2px #000;
}

.contact--logiciel {
  overflow: hidden;
  font-size: 14px;
}
.contact--logiciel > li {
  float: left;
  margin-right: 25px;
  background: url("/assets/img/contact/blender.png") left top no-repeat;
  width: 60px;
  padding-top: 65px;
  line-height: 1;
  text-align: center;
  transition: all 500ms ease 500ms;
}

.contact--logiciel li + li {
  background: url("/assets/img/contact/mixer.png") left  top no-repeat;
}
.contact--logiciel li + li + li {
  background: url("/assets/img/contact/unreal.png") left top no-repeat;
}
.contact--logiciel li + li + li + li {
  background: url("/assets/img/contact/nuke.png") left top no-repeat;
}
.contact--logiciel li + li + li + li + li {
  background: url("/assets/img/contact/cc.png") left top no-repeat;
}

.contact--social {
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
}
.contact--social > li a {
  float: left;
  width: 40px;
  height: 40px;
  margin-right: 15px;
  background: url("/assets/img/contact/link.png") left top no-repeat;
  text-indent: -555em;
  display: block;
  transition: all 300ms ease-in-out 100ms;
}
.contact--social > li a:hover {
  opacity: 0.8;
  transform:rotate(360deg);
  -ms-transform:rotate(360deg); /* IE 9 */
  -webkit-transform:rotate(360deg); /* Opera, Chrome, and Safari */
  transition: all 500ms ease-in-out 0s;
}

.contact--social li + li a{
  background: url("/assets/img/contact/twitter.png") left  top no-repeat;
}
.contact--social li + li + li a {
  background: url("/assets/img/contact/vim.png") left  top no-repeat;
}

.contact__left .intro {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 16px;
  margin: 35px 0;
  line-height: 1.4em;
  position: relative;
  text-indent: 55px;
  text-shadow: 1px 1px 2px #000;
}

.contact__left .intro:before {
  position: absolute;
  content: "";
  background: url("/assets/img/contact/quote2.png") left no-repeat;
  left: 0;
  top: -15px;
  height: 34px;
  width: 45px;
}

.contact__left .contact--cv {
  margin-top: 10px;
}
.contact__left .contact--cv a {
  display: inline-block;
  margin-top: 20px;
  font-size: 24px;
  border-bottom: 1px solid #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  padding-bottom: 1px;
  transition: all 500ms ease-in-out 0s;
}
.contact__left .contact--cv a:hover {
  opacity: 0.8;
  transition: all 500ms ease-in-out 0s;
}


.contact__right {
  float: right;
  width: 45%;
  padding-top: 35px;
}
.contact__right h2 {
  margin-bottom: 0px;
}
.contact--mail, .contact--direct {
  font-size: 28px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  background: url("/assets/img/contact/mail.png") left no-repeat;
  padding-left: 45px;
}
.contact__right em {
  font-size: 38px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-style: normal;
  direction: rtl;
  unicode-bidi: bidi-override;
}

.contact__right .contact--direct {
  margin-top: 50px;
  background: url("/assets/img/contact/direct.png") left no-repeat;
}

.contact__right input[type=email], .contact__right input[type=text], .contact__right textarea  {
  width: 100%;
  color: #fff;
  color: #000\9;
}

.quform input, .quform textarea  {
  margin-top: 15px;
  height: 38px;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

.quform textarea {
  height: 130px;
  min-height: 130px;
}

.quform .calcul .quform-input, .quform .calcul label{
  float: left;
}
.calcul {
  padding-top: 20px;
}
.quform .calcul label {padding-top: 5px; margin-right: 10px;text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.75);font-size: 19px;}
.quform .calcul input[type=text] {
  width: 70px;
  margin-left: 5px;
  margin-top: 0;
}

.btn-go {
  float: right;
  background-color: #fff;
  border-radius: 5px;
  padding: 8px 17px;
  border: none;
  color: #000;
  margin-top: 0;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  transition: all 500ms ease-in-out 0s;
}

.btn-go:hover {
  background-color: #000;
  color: #fff;
  transition: all 500ms ease-in-out 0s;
}
