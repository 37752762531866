.beer-slider {
  display: inline-block;
  position: relative;
  overflow: hidden; }
.beer-slider *, .beer-slider:before, .beer-slider:after,
.beer-slider *:before,
.beer-slider *:after {
  box-sizing: border-box; }
.beer-slider img, .beer-slider svg {
  vertical-align: bottom; }
.beer-slider > * {
  height: 100%; }
.beer-slider > img {
  max-width: 100%;
  height: auto; }

.beer-reveal {
  position: absolute;
  left: 0;
  top: 0;
  right: 50%;
  overflow: hidden;
  z-index: 1;
  opacity: 0;
  border-right: 1px solid white;
  transition: opacity 0.35s; }
.beer-reveal > :first-child {
  width: 200%;
  max-width: none;
  height: 100%; }
.beer-reveal > img:first-child {
  height: auto; }

.beer-range {
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  height: 100%;
  margin: 0;
  left: -1px;
  width: calc(100% + 2px);
  cursor: pointer;
  -webkit-appearance: slider-horizontal !important;
  -moz-appearance: none;
  opacity: 0;
  -ms-touch-action: auto;
  touch-action: auto; }
.beer-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 300vh; }
.beer-range::-moz-range-thumb {
  -webkit-appearance: none;
  height: 300vh; }
.beer-range::-ms-tooltip {
  display: none; }

.beer-handle:hover {
  cursor: w-resize;
}
.beer-handle {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: #000;
  background: rgba(255, 255, 255, 0.5);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0);
  transition: background 0.3s, box-shadow 0.3s, opacity 0.5s 0.25s; }
.beer-handle:before, .beer-handle:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  border-top: solid 2px;
  border-left: solid 2px;
  transform-origin: 0 0; }
.beer-handle:before {
  left: 10px;
  transform: rotate(-45deg);
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
}
.beer-handle:after {
  right: 0;
  transform: rotate(135deg);
  -webkit-animation: sdb05 2s infinite;
  animation: sdb05 2s infinite;
}

.beer-range:focus ~ .beer-handle {
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4); }

.beer-slider[data-beer-label]:after,
.beer-reveal[data-beer-label]:after {
  content: attr(data-beer-label);
  position: absolute;
  top: 1.5rem;
  line-height: 1;
  padding: 0.5rem;
  border-radius: 0.125rem;
  background: rgba(255, 255, 255, 0.75); }

.beer-slider[data-beer-label]:after {
  right: 1.5rem; }

.beer-reveal[data-beer-label]:after {
  left: 1.5rem; }

.beer-slider[data-beer-label=""]:after,
.beer-reveal[data-beer-label=""]:after {
  content: none; }

.beer-ready .beer-reveal, .beer-ready .beer-handle {
  opacity: 1; }


@-webkit-keyframes sdb04 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-45deg) translate(-5px, -5px);
  }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes sdb04 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-5px, -5px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(135deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(135deg) translate(-5px, -5px);
  }
  40% {
    -webkit-transform: rotate(135deg) translate(0, 0);
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(135deg) translate(0, 0);
  }
  20% {
    transform: rotate(135deg) translate(-5px, -5px);
  }
  40% {
    transform: rotate(135deg) translate(0, 0);
  }
}